/* prettier-ignore */
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Divider, Card, Empty, Table, message, Modal } from 'antd';
import {
  getOrderListApi,
  getOrderStatisticsApi,
  getIncomeStatisticsApi,
  acceptOrderApi,
  getOrderQueryParamsApi,
  finishOrderApi,
} from '../../services/order';
import FilterBar from './components/Filter';
import columns from './columns';
import './order.less';

const NULL_VALUE = 'null';

export default class Subscribe extends React.Component {
  static defaultProps = {
    title: '订阅管理',
    CardList: [
      // 订单数据: 总订单、待接单、服务中、已完成、已退款/已取消
      [
        {
          title: '总订单',
          key: 'totalCount',
          href: '/order/waiting',
          color: '#1890ff',
        },
        {
          title: '待接单',
          key: 'pendingServiceCount',
          href: '/order/waiting',
          color: '#f65650',
        },
        {
          title: '服务中',
          key: 'inServiceCount',
          href: '/order/list',
          color: '#ff8c00',
        },
        {
          title: '已完成',
          key: 'completedCount',
          href: '/order/waiting',
          color: '#32a644',
        },
        {
          title: '已退款/已取消',
          key: 'cancelledOrRefundedCount',
          href: '/order/list',
          color: '#8f959e',
        },
      ],
      // 收入数据: 总收入、本月收入、服务中、待接单、已退款
      [
        {
          title: '总收入',
          key: 'totalIncome',
          href: '/order/waiting',
          color: '#5083fb',
        },
        {
          title: '本月收入',
          key: 'monthlyIncome',
          href: '/order/waiting',
          color: '#32a644',
        },
        {
          title: '服务中',
          key: 'inServiceIncome',
          href: '/order/list',
          color: '#ed6d0d',
        },
        {
          title: '待接单',
          key: 'pendingServiceIncome',
          href: '/order/waiting',
          color: '#f64a44',
        },
        {
          title: '已退款',
          key: 'refundedIncome',
          href: '/order/list',
          color: '#9298a1',
        },
      ],
    ],
  };

  constructor() {
    super();
    this.state = {
      orderList: [],
      loading: false,
      page: 1,
      pagination: {},
      noPermission: false, // 没有权限
      initLoading: true,
      filterData: {},
      orderStatistics: {},
      incomeStatistics: {},
      // 枚举
      filterOptions: {},
      // ======== new
      tabActiveKey: '1',
    };
  }

  async componentWillMount() {
    // console.log('willMount')
    this.getOrderList();
  }

  async componentDidMount() {
    getOrderStatisticsApi({ throw: true })
      .then(res => {
        res && this.setState({ orderStatistics: res, initLoading: false });
      })
      .catch(err => {
        const noPermission = err.code === 'SCHOOL_2001';
        if (noPermission) {
          this.setState({ noPermission: true, initLoading: false });
        }
      });
    getIncomeStatisticsApi({ throw: true })
      .then(res => {
        res && this.setState({ incomeStatistics: res, initLoading: false });
      })
      .catch(err => {
        const noPermission = err.code === 'SCHOOL_2001';
        if (noPermission) {
          this.setState({ noPermission: true, initLoading: false });
        }
      });
    getOrderQueryParamsApi({ throw: true })
      .then(res => {
        res && this.setState({ filterOptions: res, initLoading: false });
      })
      .catch(err => {
        const noPermission = err.code === 'SCHOOL_2001';
        if (noPermission) {
          this.setState({ noPermission: true, initLoading: false });
        }
      });
  }

  // 获取列表数据
  getOrderList() {
    const { page, filterData } = this.state;
    const options = { page };

    this.setState({ loading: true }, async () => {
      try {
        const res = await getOrderListApi(
          {
            ...options,
            ...filterData,
            pageSize: 10,
            productCategoryId: 1, // 商品分类，暂时写死，因仅文书服务这个商品
          },
          { throw: true }
        );
        if (res) {
          this.setState({
            orderList: res.data,
            pagination: res.pagination,
          });
        }
        this.setState({ loading: false, initLoading: false });
      } catch (err) {
        const noPermission = err.code === 'SCHOOL_2001';
        if (noPermission) {
          this.setState({ loading: false, noPermission: true, initLoading: false });
        } else {
          message.error(err.msg);
          this.setState({ loading: false, initLoading: false });
        }
      }
    });
  }

  // 翻页
  handlePageChange = page => {
    this.setState({ page }, () => {
      this.getOrderList();
    });
  };

  activeTabHandle = () => {
    this.setState(preState => {
      const currentValue = preState.tabActiveKey === '1' ? '2' : '1';
      return { tabActiveKey: currentValue };
    });
  };

  // 接单
  handleOrderAccept = id => {
    acceptOrderApi(id, { throw: true })
      .then(res => {
        message.success('接单成功');
        this.getOrderList();
      })
      .catch(err => {
        console.log('err', err.message);
        message.error(err.message);
      });
  };

  // 服务完成
  handleCompleteService = id => {
    finishOrderApi(id)
      .then(res => {
        message.success('服务完成');
        this.getOrderList();
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  render() {
    const { CardList } = this.props;

    const {
      orderList,
      loading,
      pagination,
      noPermission,
      // initLoading,
      tabActiveKey, // tab切换
      orderStatistics,
      incomeStatistics,
      filterOptions,
    } = this.state;

    // 卡片数据
    const CardListData = tabActiveKey === '1' ? orderStatistics : incomeStatistics;

    // if (initLoading) {
    //   return <div className="order-container no-permission">加载中...</div>;
    // }

    if (noPermission) {
      return <div className="order-container no-permission">未开启订单数据板块权限</div>;
    }

    return (
      <div className="order-container">
        <Alert
          showIcon
          closable
          type="info"
          message="用户评价的数量和分数影响产品排序，请在服务结束户继续邀请用户对产品及服务进行评价"
        />
        <div className="Order_tabs-box">
          <span
            className={`Order_tab-item ${tabActiveKey === '1' ? 'Order_tab-item-active' : ''}`}
            onClick={this.activeTabHandle}
          >
            订单数据
          </span>
          <span
            className={`Order_tab-item ${tabActiveKey === '2' ? 'Order_tab-item-active' : ''}`}
            onClick={this.activeTabHandle}
          >
            收入数据
          </span>
        </div>
        <header>
          {CardList[+tabActiveKey - 1].map((card, index) => {
            const cardValue =
              tabActiveKey === '1' ? CardListData[card.key] || 0 : (CardListData[card.key] || {}).display || {};

            return (
              <Card key={`Order_cardList_${index}`} hoverable className="card">
                <Card.Meta
                  title={card.title}
                  description={
                    <div className="total-stat-num big-num" style={{ color: card.color }} id="totalCount">
                      {tabActiveKey === '1' ? (
                        cardValue
                      ) : (
                        <span>
                          <em style={{ fontStyle: 'normal' }}>{cardValue.prefix || '-'}</em>
                          {cardValue.text || '-'}
                        </span>
                      )}
                    </div>
                  }
                />
              </Card>
            );
          })}
          <div className="empty-box" />
        </header>
        <div className="filter-box">
          <FilterBar
            filterOptions={filterOptions}
            onFilterChange={filterData => {
              this.setState({ filterData, page: 1 }, () => {
                this.getOrderList();
              });
            }}
          />
        </div>
        <Table
          rowKey="id"
          loading={loading}
          columns={[
            ...columns,
            {
              title: '操作',
              dataIndex: 'action',
              key: 'action',
              width: 100,
              render: (_, record) => (
                <span>
                  {/* 已付款待服务可以接单 2 */}
                  {`${record.orderStatus}` === '2' && (
                    <span>
                      <a
                        onClick={() => {
                          Modal.confirm({
                            // title: '确认接单',
                            content: '是否确认接单？',
                            onOk: () => {
                              this.handleOrderAccept(record.id);
                            },
                          });
                        }}
                      >
                        接单
                      </a>
                      <Divider type="vertical" />
                    </span>
                  )}
                  {`${record.orderStatus}` === '3' && (
                    <span>
                      <a
                        onClick={() => {
                          Modal.confirm({
                            // title: '确认服务完成',
                            content: '是否确认订单完成？',
                            onOk: () => {
                              this.handleCompleteService(record.id);
                            },
                          });
                        }}
                      >
                        服务完成
                      </a>
                      <Divider type="vertical" />
                    </span>
                  )}
                  <Link to={`/order/detail/${record.id}`}>查看详情</Link>
                </span>
              ),
            },
          ]}
          dataSource={orderList || []}
          pagination={{
            current: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.count,
            showTotal: total => `总计${total}条数据`,
            onChange: this.handlePageChange,
          }}
          locale={{
            emptyText: (
              <Empty
                description={
                  <span>
                    <span className="display-block">暂无数据</span>
                  </span>
                }
              />
            ),
          }}
        />
      </div>
    );
  }
}
