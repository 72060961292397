import React from 'react';
import moment from 'moment';

export default [
  {
    title: '订单编号',
    dataIndex: 'id',
    key: 'id',
    width: 120,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 120,
    render(result) {
      return result ? moment(result).format('YYYY-MM-DD HH:mm:ss') : '--';
    },
  },
  {
    title: '客户账号ID',
    dataIndex: 'userId',
    key: 'userId',
    width: 100,
  },
  {
    title: '客户账号昵称',
    dataIndex: 'userName',
    key: 'userName',
    width: 100,
  },
  {
    title: '订单收入',
    dataIndex: 'settlementPrice',
    key: 'settlementPrice',
    width: 80,
    render(result) {
      const constant = result?.display || {};
      return constant.prefix + constant.text;
    },
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatusText',
    key: 'orderStatusText',
    width: 60,
  },
  {
    title: '申请阶段',
    dataIndex: 'productDegreeName',
    key: 'productDegreeName',
    width: 60,
  },
  {
    title: '国家/地区',
    dataIndex: 'productRegionName',
    key: 'productRegionName',
    width: 80,
  },
  {
    title: '服务类型',
    dataIndex: 'productSubcategoryName',
    key: 'productSubcategoryName',
    width: 60,
    render(result) {
      // console.log('result', result);
      return result;
    },
  },
  {
    title: '产品名称',
    dataIndex: 'productName',
    key: 'productName',
    width: 80,
    render(result) {
      // console.log('result', result);
      return result;
    },
  },
  {
    title: '导师类型',
    dataIndex: 'productSupplierName',
    key: 'productSupplierName',
    width: 80,
    render(result) {
      // console.log('result', result);
      return result;
    },
  },
  {
    title: '额外项目',
    dataIndex: 'customItems',
    key: 'customItems',
    width: 150,
    render(result) {
      return (
        <ul style={{ paddingLeft: '10px' }}>
          {result?.map((item, index) => {
            return <li key={`customItems_${index}`}>{item}</li>;
          })}
        </ul>
      );
    },
  },
];
