import axios from '../common/axios';

// 获取订单列表数据
export const getOrderListApi = (params = {}, opts = {}) => {
  return axios.get('/third_party_consultant/mall/order/list', { ...params }, opts);
};

// 获取订单量统计数据
export const getOrderStatisticsApi = (opts = {}) => {
  return axios.get('/third_party_consultant/mall/order/stats', {}, opts);
};

// 获取收入统计数据
export const getIncomeStatisticsApi = (opts = {}) => {
  return axios.get('/third_party_consultant/mall/order/income-stats', {}, opts);
};

// 接单
export const acceptOrderApi = orderId => {
  return axios.post('/third_party_consultant/mall/order/accept', { orderId });
};

// 完成服务
export const finishOrderApi = orderId => {
  return axios.post('/third_party_consultant/mall/order/complete', { orderId });
};

// 获取订单详情
export const getOrderDetailApi = orderId => {
  return axios.get('/third_party_consultant/mall/order/detail', { orderId });
};

// 获取订单用户联系方式
export const getOrderUserContactApi = orderId => {
  return axios.get('/third_party_consultant/mall/order/contact', { orderId });
};

// 获取订单查询参数的枚举值
export const getOrderQueryParamsApi = (opts = {}) => {
  return axios.get('/third_party_consultant/mall/order/query-enums', {}, opts);
};
