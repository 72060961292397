// OrderDetail.js
import React, { Component } from 'react';
import { Card, Button, Modal, Upload, Input, Form, message } from 'antd';
import { getOrderDetailApi, getOrderUserContactApi, acceptOrderApi, finishOrderApi } from '../../services/order';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import './detail.less';

const { Item } = Form;

class OrderDetail extends Component {
  state = {
    orderInfo: {
      orderNumber: '123456',
      createTime: '2023-10-01',
      customerAccount: 'customer123',
      orderAmount: '1000',
      paymentStatus: '已支付',
      orderStatus: '待接单',
      applicationStage: '阶段1',
      applicationCountry: '美国',
      serviceType: '套餐服务',
      additionalItems: ['头脑风暴', '材料递交'],
    },
    personInfo: {},
    showTakeOrderButton: true,
    showCompleteServiceButton: false,
    showProfileModal: false,
    profileImage: null,
  };

  componentDidMount() {
    this.getInfoData();
  }

  // 获取数据
  getInfoData = () => {
    const { params } = this.props.match || {};
    //  获取订单信息
    getOrderDetailApi(params.id).then(res => {
      if (res) {
        this.setState({ orderInfo: res || {} });
      }
    });
    // 获取用户联系方式
    setTimeout(() => {
      getOrderUserContactApi(params.id).then(res => {
        if (res) {
          this.setState({ personInfo: res || {} });
        }
      });
    }, 1000);
  };

  // 接单
  handleTakeOrder = id => {
    acceptOrderApi(id)
      .then(res => {
        message.success('接单成功');
        this.getInfoData();
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  // 服务完成
  handleCompleteService = id => {
    finishOrderApi(id)
      .then(res => {
        message.success('服务完成');
        this.getInfoData();
      })
      .catch(err => {
        message.error(err.message);
      });
  };

  handleProfileModal = () => {
    this.setState({ showProfileModal: true });
  };

  handleProfileUpload = info => {
    if (info.file.status === 'done') {
      this.setState({ profileImage: info.file.response.url });
    }
  };

  handleProfileSubmit = () => {
    // 处理上传的导师 Profile
    this.setState({ showProfileModal: false });
  };

  render() {
    const {
      orderInfo,
      personInfo,
      showTakeOrderButton,
      showCompleteServiceButton,
      showProfileModal,
      profileImage,
    } = this.state;
    const {
      id,
      userName,
      createTime,
      orderStatusText,
      settlementPrice = {},
      productRegionName = '', // 国家/地区
      productDegreeName = '', // 阶段
      customItems = [],
      orderStatus,
      productSubcategoryName,
      productName,
      productSupplierName,
    } = orderInfo;
    const { contactMobile, contactMobileCountry, contactNote, contactWechat } = personInfo || {};

    const display = settlementPrice?.display || {};

    return (
      <div className="order-detail-container">
        <Card title="订单基本信息" className="info-card order-info-card">
          <p>订单编号: {id}</p>
          <p>创建时间: {createTime && moment(createTime)?.format('YYYY-MM-DD HH:mm:ss')}</p>
          <p>客户账号: {userName}</p>
          <p>
            订单收入: {display.prefix}
            {display.text}
          </p>
          <p>订单状态: {orderStatusText}</p>
        </Card>

        <Card title="个人信息" className="info-card service-info-card">
          <p>姓名: {personInfo.userName}</p>
          <p>
            联系电话: +{contactMobileCountry}&nbsp;{contactMobile}
          </p>
          <p>微信号: {contactWechat}</p>
          <p>备注: {contactNote}</p>
        </Card>

        <Card title="服务需求信息" className="info-card service-info-card">
          <p>申请阶段: {productDegreeName}</p>
          <p>申请国家: {productRegionName}</p>
          <p>服务类型: {productSubcategoryName}</p>
          <p>产品名称: {productName}</p>
          <p>导师类型: {productSupplierName}</p>
          {customItems.length > 0 && (
            <div>
              <p>额外项目:</p>
              <ul>
                {customItems.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </Card>

        {[2, 3].includes(+orderStatus) && (
          <Card title="操作区域" className="info-card action-card operation-area">
            {/* 已支付待服务 2 */}
            {+orderStatus === 2 && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    // title: '确认接单',
                    content: '是否确认接单？',
                    onOk: () => {
                      this.handleTakeOrder(id);
                    },
                  });
                }}
              >
                接单
              </Button>
            )}
            {/* 服务中 3 */}
            {+orderStatus === 3 && (
              <Button
                type="primary"
                onClick={() => {
                  Modal.confirm({
                    // title: '确认服务完成',
                    content: '是否确认订单完成？',
                    onOk: () => {
                      this.handleCompleteService(id);
                    },
                  });
                }}
              >
                服务完成
              </Button>
            )}
            {/* <Button type="default" onClick={this.handleProfileModal}>
              录入接单导师信息
            </Button> */}
          </Card>
        )}

        <Modal
          title="录入接单导师信息"
          visible={showProfileModal}
          onOk={this.handleProfileSubmit}
          onCancel={() => this.setState({ showProfileModal: false })}
        >
          <Form layout="vertical">
            <Item label="导师 Profile">
              <Upload
                name="profile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  // 模拟上传成功
                  setTimeout(() => {
                    onSuccess({ url: URL.createObjectURL(file) });
                  }, 0);
                }}
                onChange={this.handleProfileUpload}
              >
                {profileImage ? (
                  <img src={profileImage} alt="profile" style={{ width: '100%' }} />
                ) : (
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>上传图片</div>
                  </div>
                )}
              </Upload>
            </Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default OrderDetail;
