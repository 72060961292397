import React from 'react';

import { Tabs, Alert, Tag, Modal, Empty, Table, Tooltip, Space } from 'antd';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Filter from '../../component/filter/filter';
import Title from '../../component/title';
import { getPlanList, getPlanTypeData } from '../../services/plans';
import './plans.less';

const { TabPane } = Tabs;

const TEXT_TIP =
  '因业务调整，方案将不再展示在产品首页&各院校页面，仅在个人主页和搜索结果中可见，如需业务合作请发邮件：biz@xuanxiaodi.com';
export default class PlanList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageKey: '',
      visiblePlanSet: false,
      planInfo: {},
      currentList: [],
      classData: [],
      pagination: {
        page: 1,
        pageSize: 20,
      },
    };
    this.callbacks = {};
  }

  componentDidMount() {
    const { location: { query: { tab = '' } = {} } = {} } = this.props;
    getPlanTypeData().then(res => {
      this.setState(
        {
          classData: res,
          pageKey: String(tab || (res[0] || {}).id || '1'),
        },
        () => {
          this.refreshData({ typeId: this.state.pageKey });
        }
      );
    });
  }

  refreshData = appendFilter => {
    const { pagination = {}, pageKey, filterCondition } = this.state;
    const filterParams = {
      majorKeyword: '',
      keyword: '',
      subtypeIds: [],
      page: 1,
    };
    const filterKey = _.keys(filterCondition);
    filterKey.forEach(k => {
      if (k === 'majorKeyword') {
        filterParams.majorKeyword = filterCondition[k];
      } else if (k === 'keyword') {
        if (filterCondition[k]) {
          filterParams.keyword = filterCondition[k];
        }
      } else if (k === 'enabled') {
        filterParams.enabled = filterCondition[k];
      } else if (k === 'reviewStatus') {
        filterParams.reviewStatus = filterCondition[k];
      } else if (filterCondition[k]) {
        filterParams.subtypeIds.push(filterCondition[k]);
      }
    });
    // console.log(filterKey, filterParams, filterCondition);
    const originFilter = {
      ...filterParams,
      page: pagination.page || 1,
      pageSize: pagination.pageSize || 20,
      typeId: pageKey,
    };
    const filter = { ...originFilter, ...appendFilter };
    getPlanList(filter).then(res => {
      this.setState({
        pagination: res.pagination,
        currentList: res.data,
      });
    });
  };

  // 切换tabs
  switchPage = pageKey => {
    this.setState(
      {
        pageKey,
        filterCondition: {},
      },
      () => {
        this.onSelectChange();
        window.history.pushState(null, null, `?tab=${pageKey}`);
      }
    );
  };

  // 筛选框搜索
  onSelectChange = (key, value) => {
    let { filterCondition } = this.state;
    filterCondition = { ...filterCondition, [key]: value };
    this.setState(
      {
        filterCondition,
      },
      () => {
        this.refreshData();
      }
    );
  };

  openPlanPreviewModal = planInfo => {
    this.setState({
      visiblePlanSet: true,
      planInfo,
    });
  };

  closePlanPreviewModal = () => {
    this.setState({ visiblePlanSet: false });
  };

  renderPlanSetModal = () => {
    const { visiblePlanSet, planInfo } = this.state;
    return (
      <Modal
        visible={visiblePlanSet}
        title="预览方案详情"
        destroyOnClose
        width={375}
        centered
        onCancel={this.closePlanPreviewModal}
        onOk={this.closePlanPreviewModal}
        bodyStyle={{ padding: 0 }}
      >
        <iframe srcDoc={planInfo?.html || ''} title="preview" style={{ width: '100%', height: 640, border: 0 }} />
      </Modal>
    );
  };

  openAddPlanModal = () => {
    Modal.info({
      title: '提示',
      content: TEXT_TIP,
      onOk: () => {
        console.log('fefef');
        this.props.history.push('/plan/detail/');
      },
    });
  };

  render() {
    const { pageKey, classData = [], currentList = [], pagination = {} } = this.state;
    const subtypes = (classData.find(c => c.id === Number(pageKey)) || {}).subtypes || [];
    const isShowMajor = classData.find(c => c.id === Number(pageKey))?.data?.withMajors;
    const columns = subtypes.map(item => {
      return {
        title: `全部${item.name}`,
        key: item.id,
        type: 'select',
        dataKey: 'id',
        dataIndex: item.id,
        dataTitle: 'name',
        data: item.options,
        // controlled: true,
        // value: this.state.filterCondition?.[item.id],
      };
    });
    const dataSource = {};
    columns.forEach(item => {
      dataSource[item.dataIndex] = item.data;
    });
    const tableColumns = [
      {
        title: '方案名称',
        key: 'name',
        dataIndex: 'name',
        width: 200,
        align: 'center',
      },
      {
        title: '标签',
        key: 'coverTag',
        dataIndex: 'coverTag',
        width: 200,
        align: 'center',
        render: (coverTag, row) => (
          <div>
            <Tag color="blue" style={{ margin: 4 }}>
              {coverTag}
            </Tag>
            {_.keys(row.subtype).map(key => (
              <Tag color="green" style={{ margin: 4 }} key={key}>
                {row.subtype[key].name}
              </Tag>
            ))}
          </div>
        ),
      },
      isShowMajor
        ? {
            title: '关联专业',
            key: 'majors',
            dataIndex: 'majors',
            width: 120,
            align: 'center',
            render: majors => <div>{(majors || []).map(item => item?.name).join()}</div>,
          }
        : {},
      {
        title: '营销词',
        key: 'marketTags',
        dataIndex: 'marketTags',
        align: 'center',
        render: (marketTags, row, idx) => (
          <div>
            {marketTags &&
              marketTags.map(tag => (
                <Tag color="orange" style={{ margin: 4 }} key={tag}>
                  {tag}
                </Tag>
              ))}
          </div>
        ),
      },
      {
        title: '审核状态',
        width: '105px',
        key: 'reviewStatus',
        dataIndex: 'reviewStatusText',
        align: 'center',
        render: (reviewStatusText, row) => (
          <span>{row.reviewStatus === 2 ? `${row.reviewStatusText}: ${row.reviewNote}` : row.reviewStatusText}</span>
        ),
      },
      {
        title: '是否已上线',
        key: 'enabled',
        dataIndex: 'enabled',
        width: 100,
        align: 'center',
        render: enabled => <Space>{enabled ? '是' : '否'}</Space>,
      },
      {
        title: '操作',
        key: 'edit',
        dataIndex: 'id',
        width: 140,
        align: 'center',
        render: (id, row) => (
          <Space>
            <Tooltip title="预览" onClick={() => this.openPlanPreviewModal(row)}>
              <a className="color-blue">预览</a>
            </Tooltip>
            <Link to={`/plan/detail/${id}`}>编辑</Link>
          </Space>
        ),
      },
    ].filter(v => v.title);
    return (
      <div className="container-plan">
        <Title text="方案列表" />
        <Alert showIcon closable type="info" message={TEXT_TIP} />
        <div style={{ marginBottom: 20 }} />
        {classData && classData.length > 0 && (
          <Tabs
            activeKey={pageKey}
            onChange={this.switchPage}
            tabBarExtraContent={
              // <Link to="/plan/detail/">添加方案</Link>
              <div
                style={{ color: '#1890ff', cursor: 'pointer', margin: '0 0 20px 0' }}
                onClick={this.openAddPlanModal}
              >
                添加方案
              </div>
            }
          >
            {classData.map(c => (
              <TabPane key={c.id} tab={c.name} />
            ))}
          </Tabs>
        )}
        <Filter
          initState={pageKey}
          columns={[
            ...columns,
            {
              title: '上下线状态',
              key: 'enabled',
              type: 'select',
              dataIndex: 'enabledMap',
              dataTitle: 'name',
              dataKey: 'value',
            },
            {
              title: '审核状态',
              key: 'reviewStatus',
              type: 'select',
              dataIndex: 'reviewStatusMap',
              dataTitle: 'name',
              dataKey: 'value',
            },
            {
              title: '按名称搜索方案',
              key: 'keyword',
              type: 'input',
              defaultValue: '',
            },
            isShowMajor
              ? {
                  title: '按关联专业搜索方案',
                  key: 'majorKeyword',
                  type: 'input',
                  defaultValue: '',
                }
              : {},
          ]}
          data={{
            ...dataSource,
            enabledMap: [
              {
                name: '上线',
                value: true,
              },
              {
                name: '下线',
                value: false,
              },
            ],
            reviewStatusMap: [
              {
                name: '审核未提交',
                value: 0,
              },
              {
                name: '审核通过',
                value: 1,
              },
              {
                name: '审核被拒',
                value: 2,
              },
              {
                name: '等待审核',
                value: 3,
              },
            ],
          }}
          onChange={this.onSelectChange}
          marginTop="10px"
        />
        <br />
        {currentList.length > 0 ? (
          <Table
            dataSource={currentList}
            rowKey="id"
            columns={tableColumns}
            pagination={{
              current: pagination.page,
              pageSize: pagination.pageSize,
              total: pagination.count,
              onChange: (page, pageSize) => this.refreshData({ page, pageSize }),
            }}
          />
        ) : (
          <Empty description="暂无方案，点击右上角【添加方案】按钮创建第一个方案吧～" />
        )}
        {this.renderPlanSetModal()}
      </div>
    );
  }
}
