import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { Input, DatePicker, Select, Checkbox, Button } from 'antd';
import moment from 'moment';
import './Filter.less';

const { RangePicker } = DatePicker;
const { Option } = Select;

class FilterBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderStatusList: [],
      degreeList: [],
      regionList: [],
      subcategoriesList: [],
      urgentLevelList: [],
      formItems: {
        orderId: '', // 订单编号
        orderTime: [], // 订单时间范围
        orderAmount: [], // 订单金额范围
        // paymentStatus: '',
        orderStatus: '', // 订单状态
        degree: '', // 申请阶段
        region: '', // 申请国家
        productSubcategoryId: '',
        urgentLevel: [],
      },
    };
  }

  componentWillReceiveProps(prevProps) {
    if (!isEqual(prevProps.filterOptions, this.props.filterOptions)) {
      const { orderStatus = [], degree = [], region = [], subcategories = [], urgentLevel = [] } =
        prevProps.filterOptions || {};
      this.setState({
        orderStatusList: orderStatus,
        degreeList: degree,
        regionList: region,
        subcategoriesList: subcategories,
        urgentLevelList: urgentLevel?.filter(({ id }) => +id !== 1) || [],
      });
    }
  }

  // 更新表单数据
  updateFormItems = formItem => {
    const key = Object.keys(formItem)[0];
    this.setState(prevState => {
      const currentValue = prevState.formItems;
      return { formItems: { ...currentValue, ...formItem } };
    });
  };

  handleOrderNumberChange = e => {
    this.updateFormItems({ orderId: e.target.value });
  };

  handleOrderTimeChange = dates => {
    this.updateFormItems({ orderTime: dates || [] });
  };

  handleOrderAmountChange = (index, value) => {
    const oldOrderAmount = this.state.formItems?.orderAmount || [];
    const orderAmount = [...oldOrderAmount];
    orderAmount[index] = value;
    this.updateFormItems({ orderAmount });
  };

  handleOrderStatusChange = value => {
    this.updateFormItems({ orderStatus: value });
  };

  handleApplicationStageChange = value => {
    this.updateFormItems({ degree: value });
  };

  handleApplicationCountryChange = value => {
    this.updateFormItems({ region: value });
  };

  handleServiceTypeChange = value => {
    this.updateFormItems({ productSubcategoryId: value });
  };

  handleAdditionalItemsChange = checkedValues => {
    this.updateFormItems({ urgentLevel: checkedValues });
  };

  handleFilterChange = () => {
    const { onFilterChange } = this.props;
    const formItems = this.state.formItems;
    const formData = {};

    for (let key in formItems) {
      if (Array.isArray(formItems[key]) && formItems[key].length === 0) {
        continue;
      }
      if (key === 'orderTime') {
        formData.startDate = formItems[key][0]?.format('YYYY-MM-DD') || '';
        formData.endDate = formItems[key][1]?.format('YYYY-MM-DD') || '';
        continue;
      }
      if (key === 'orderAmount') {
        if (formItems[key][0]) {
          formData.minAmount = formItems[key][0] * 100;
        }
        if (formItems[key][1]) {
          formData.maxAmount = formItems[key][1] * 100;
        }
        continue;
      }
      if (key === 'urgentLevel') {
        formData.urgentLevel = formItems[key].join(',');
        continue;
      }
      if (key === 'orderStatus' && typeof formItems[key] !== 'string') {
        formData.orderStatus = formItems[key];
        continue;
      }
      if (!!formItems[key]) {
        formData[key] = formItems[key];
      }
    }
    onFilterChange(formData);
  };

  render() {
    const { formItems, orderStatusList, degreeList, regionList, subcategoriesList, urgentLevelList } = this.state;
    const {
      orderId,
      orderTime,
      orderAmount,
      orderStatus,
      degree,
      region,
      productSubcategoryId,
      urgentLevel,
    } = formItems;

    return (
      <div className="Order__filter-bar-container">
        <Input
          placeholder="订单编号"
          value={orderId}
          onChange={this.handleOrderNumberChange}
          className="Order__filter-bar-item"
        />
        <RangePicker
          value={orderTime}
          onChange={this.handleOrderTimeChange}
          disabledDate={current => current && current > moment().endOf('day')}
          className="Order__filter-bar-item datepicker-item"
        />
        <Input
          placeholder="最低金额(元)"
          value={orderAmount[0]}
          onChange={e => this.handleOrderAmountChange(0, e.target.value)}
          className="Order__filter-bar-item amount-input amount-input--b"
        />{' '}
        -
        <Input
          placeholder="最高金额(元)"
          value={orderAmount[1]}
          onChange={e => this.handleOrderAmountChange(1, e.target.value)}
          className="Order__filter-bar-item amount-input amount-input--s"
        />
        <Select value={orderStatus} onChange={this.handleOrderStatusChange} className="Order__filter-bar-item">
          <Option value="">请选择订单状态</Option>
          {orderStatusList.length > 0 &&
            orderStatusList.map((item, index) => {
              return (
                <Option key={'orderstatus_' + index} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
        <Select value={degree} onChange={this.handleApplicationStageChange} className="Order__filter-bar-item">
          <Option value="">请选择申请阶段</Option>
          {degreeList.length > 0 &&
            degreeList.map((item, index) => {
              return (
                <Option key={'degree_' + index} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
        <Select value={region} onChange={this.handleApplicationCountryChange} className="Order__filter-bar-item">
          <Option value="">请选择申请国家</Option>
          {regionList.length > 0 &&
            regionList.map((item, index) => {
              return (
                <Option key={'region_' + index} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
        <Select value={productSubcategoryId} onChange={this.handleServiceTypeChange} className="Order__filter-bar-item">
          <Option value="">请选择服务类型</Option>
          {subcategoriesList.length > 0 &&
            subcategoriesList.map((item, index) => {
              return (
                <Option key={'subcategories_' + index} value={item.id}>
                  {item.name}
                </Option>
              );
            })}
        </Select>
        <Checkbox.Group
          options={urgentLevelList.map(({ name, id }) => ({ label: name, value: id }))}
          value={urgentLevel}
          onChange={this.handleAdditionalItemsChange}
          className="checkbox-group"
        />
        <Button type="primary" onClick={this.handleFilterChange}>
          筛选
        </Button>
      </div>
    );
  }
}

export default FilterBar;
